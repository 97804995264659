<template>
  <div>
    <!-- <b-alert v-if="!selectedOrgId" v-model="showDismissibleAlert" class="p-1" dismissible variant="dark">
    Select 'Startup' from the dropdown below, then click on 'Associate' to join an incubator. Manager options will appear upon associating with an incubator.
  </b-alert> -->
    <b-row>
      <b-col cols="12">
        <b-overlay
          :show="$apollo.loading || mutationLoading"
          spinner-variant="primary"
        >
          <b-card>
            <div class="d-flex  align-items-center justify-content-between">
              <div class="d-flex align-items-baseline ">
                <!-- <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-secondary"
                @click="$router.back()"
              >
                <feather-icon
                  class="mr-25"
                  icon="ChevronLeftIcon"
                />
              </b-button> -->
                <h3 class="">
                  Forms
                </h3>
              </div>
            </div>
            <hr>
            <!-- search input -->
            <div class="custom-search d-flex justify-content-between mt-2 ">
              <b-form-group>
                <div class="d-flex align-items-center">
                  <label class="mr-1">Search</label>
                  <b-form-input
                    v-model="searchTerm"
                    class="d-inline-block mr-1"
                    placeholder="Search Forms"
                    type="text"
                  />
                </div>
              </b-form-group>
            </div>

            <!-- table -->
            <vue-good-table
              :columns="isUnderUserOrgs ? opencolumns : columns"
              :pagination-options="{
                enabled: true,
                perPage:pageLength
              }"
              :rows="forms"
              :search-options="{
                enabled: true,
                externalQuery: searchTerm }"
            >
              <template
                slot="table-row"
                slot-scope="props"
              >
                <span
                  v-if="props.column.field === 'action'"
                >
                  <b-button
                    v-if="props.row.status === 'enabled'"
                    :to="{ name: 'incubator-forms-your-response', params: { fid: props.row.id, mname: props.row.title } }"
                    class="mr-50"
                    size="sm"
                    variant="outline-primary"
                  >
                    {{
                      props.row.programs_applicantstables.length === 0 || props.row.programs_applicantstables[0].programs_applicationresponsetables_aggregate.aggregate.count === 0 ? 'Respond' : 'View Response'
                    }}

                  </b-button>
                  <b-button
                    v-else
                    size="sm"
                    variant="outline-primary"
                    class="mr-50"
                    :to="{ name: 'incubator-forms-your-response', params: { fid: props.row.id, mname: props.row.title } }"
                  >
                    View Form
                  </b-button>
                  <b-button
                    v-if="!props.row.is_default"
                    size="sm"
                    variant="outline-primary"
                    class="mr-50"
                    @click="copyToClipboard(`startup-portal/forms/responses/${props.row.id}/${props.row.title}/response`)"
                  >
                    <feather-icon icon="CopyIcon" />
                  </b-button>
                </span>
                <span
                  v-else-if="props.column.field === 'title'"
                  class="d-flex justify-content-between"
                >
                  <span>
                    {{ props.row.title }}
                  </span>
                  <div style="text-align: right;">
                    <span>
                      <b-badge
                        v-if="props.row.status === 'enabled'"
                        variant="light-primary"
                      >
                        Enabled
                      </b-badge>
                      <b-badge
                        v-else
                        variant="secondary"
                      >
                        Disabled
                      </b-badge>
                    </span>
                    <br>
                    <span>
                      <b-badge
                        v-if="props.row.programs_applicantstables.length === 0 || props.row.programs_applicantstables[0].programs_applicationresponsetables_aggregate.aggregate.count === 0"
                        variant="light-danger"
                      >
                        Unanswered
                      </b-badge>
                      <b-badge
                        v-else
                        variant="light-success"
                      >
                        Answered
                      </b-badge>
                    </span>
                  </div>
                </span>
                <span
                  v-else-if="props.column.field === 'incubator'"
                >
                  <p class="limit-characters">
                    {{ props.row.incubator }}
                  </p>
                </span>
              </template>
              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap">
                      Showing 1 to
                    </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['25','50','100']"
                      class="mx-1"
                      @input="(value)=>props.perPageChanged({currentPerPage:value})"
                    />
                    <span class="text-nowrap"> of {{ props.total }} entries </span>
                  </div>
                  <div>
                    <b-pagination
                      :per-page="pageLength"
                      :total-rows="props.total"
                      :value="1"
                      align="right"
                      class="mt-1 mb-0"
                      first-number
                      last-number
                      next-class="next-item"
                      prev-class="prev-item"
                      @input="(value)=>props.pageChanged({currentPage:value})"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-card>
        </b-overlay>
        <b-modal
          id="view-incubator-modal"
          title="View Details"
          hide-footer
        >
          <b-img
            src="https://picsum.photos/600/300/?image=25"
            style="width: 100%;"
          />
          <b-table
            v-if="incubators && incubators[selectedRow]"
            :fields="['title','official_email','official_phone','url','city','state','industry',{key: 'startup_stage', label: 'Stage'},'description']"
            stacked
            bordered
            :items="[incubators[selectedRow]]"
          >
            <template #cell(url)="data">
              <b-link
                :href="prependHttps(data.item.url)"
                target="_blank"
              >{{ data.item.url }}
              </b-link>
            </template>
          </b-table>
        </b-modal>
        <b-modal
          id="join-incubator-modal"
          class="font-medium-2"
          title="Join Incubator's Network"
          ok-title="Join"
          centered
          @ok="joinIncubator(incubators[selectedRow].id)"
        ><span
          v-if="selectedOrgId && incubators[selectedRow]"
        >Would you like to join the network of <strong class="text-primary d-inline-block">{{
          incubators[selectedRow].title
        }}</strong> with your organisation <strong class="text-primary d-inline-block">{{
          startupOrgs.find(e => e.id === selectedOrgId).title
        }}</strong>?</span>
        </b-modal>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton, BCard, BFormGroup, BFormInput, BFormSelect, BLink, BPagination, BTable, BImg, BBadge,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
// import store from '@/store'
import gql from 'graphql-tag'
import { getUserData } from '@/auth/utils'
// import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BLink,
    BTable,
    VueGoodTable,
    BPagination,
    BFormGroup,
    BImg,
    BBadge,
    // BAlert,
    BFormInput,
    BFormSelect,
    BButton,
    // vSelect,
    // BInputGroup,
  },
  data() {
    return {
      pageLength: 25,
      showDismissibleAlert: true,
      dir: false,
      filter: null,
      isUnderUserOrgs: !this.getIncubatorFromSubdomain(),
      columns: [
        {
          label: 'Title',
          field: 'title',
        },
        {
          label: 'Description',
          field: 'description',
        },
        {
          label: 'Date Created',
          field(row) {
            if (!row.created_at) return '-'
            return `${new Date(row.created_at)
              .toLocaleString('en-US', {
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                timeZone: getUserData().timezone.abbr,
                hour12: true,
              })}`
          },
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      opencolumns: [
        {
          label: 'Title',
          field: 'title',
        },
        {
          label: 'Description',
          field: 'description',
        },
        {
          label: 'Incubator',
          field: 'incubator',
        },
        {
          label: 'Date Created',
          field(row) {
            if (!row.created_at) return '-'
            return `${new Date(row.created_at)
              .toLocaleString('en-US', {
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                timeZone: getUserData().timezone.abbr,
                hour12: true,
              })}`
          },
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      forms: [],
      mutationLoading: false,
      startupOrgs: [],
      getRole: [],
      searchTerm: '',
      copyLink: '',
      selectedRow: null,
      selectedOrgId: null,
    }
  },
  methods: {
    copyToClipboard(text) {
      const newtext = `${window.location.origin}/${text}`
      if (navigator.clipboard) {
        navigator.clipboard.writeText(newtext).then(() => {
          this.$bvToast.toast('Form link copied to clipboard', {
            title: 'Success',
            variant: 'success',
            solid: true,
            autoHideDelay: 3000,
          })
        }).catch(() => {
        })
      } else {
        const textarea = document.createElement('textarea')
        textarea.value = newtext
        textarea.style.position = 'fixed' // ensure the textarea is rendered offscreen
        document.body.appendChild(textarea)
        textarea.focus()
        textarea.select()
        try {
          document.execCommand('copy') // execute the copy command
        } catch (err) {
          // failed to copy
        } finally {
          document.body.removeChild(textarea) // remove the textarea from the DOM
        }
      }
    },

    // Insert into entry with incubator_id and organization_id into users_associationtables table with graphql mutate
    joinIncubator(incubatorId) {
      this.mutationLoading = true
      const mutation = gql`
        mutation {
          insert_users_associationtable_one(
            object: {
              organization_id: ${this.selectedOrgId},
              incubator_id: ${incubatorId},
              role: "startup",
            }
          ) {
            id
          }
        }
      `
      this.$apollo.mutate({
        mutation,
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successfully joined incubator\'s network.',
              icon: 'CompassIcon',
              variant: 'success',
            },
          })

          this.mutationLoading = false
          this.$apollo.queries.incubators.refetch()
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong. Please try again later.',
              message: error.message,
              icon: 'CompassIcon',
              variant: 'danger',
            },
          })
          this.mutationLoading = false
        })
    },
    getRolePlacement(role) {
      if (role === 'startup') {
        return 'network'
      }
      return role
    },
  },
  apollo: {
    forms: {
      query() {
        const subdomain = this.getIncubatorFromSubdomain() || '.*'
        return gql`
          query Forms {
            users_associationtable(
              where: {
                organization_id: {_eq: ${getUserData().associatedOrgDetails[0].organization_id}},
                usersOrganizationtableByIncubator: {
                  domain: {_regex: "${subdomain}"}
                }
              }
            ) {
              id
              role
              usersOrganizationtableByIncubator {
                title
                programs_applicationformstables(where: {is_form: {_eq: true}, status: {_neq: "draft"}}) {
                  id
                  description
                  created_at
                  title
                  status
                  type
                  programs_applicantstables(where: {organization_id_id: {_eq: ${getUserData().associatedOrgDetails[0].organization_id}}}) {
                    programs_applicationresponsetables_aggregate {
                      aggregate {
                        count(columns: id)
                      }
                    }
                  }
                }
              }
            }
          }`
      },
      fetchPolicy: 'network-only',
      update(data) {
        const temp = []
        data.users_associationtable.map(resp => {
          resp.usersOrganizationtableByIncubator.programs_applicationformstables.map(rowdata => {
            if (rowdata.type.includes(resp.role)) {
              const exists = temp.some(item => item.id === rowdata.id)
              if (!exists) {
                temp.push({
                  ...rowdata,
                  incubator: resp.usersOrganizationtableByIncubator.title,
                })
              }
            }
            return 0
          })
          return 0
        })
        return temp
      },
    },
    startupOrgs: {
      query() {
        return gql`
          query startup_organizations {
          users_organizationtable(where: {users_associationtables: { role: {_eq: "startup"}, incubator_id: {_is_null: true}, user_id: {_eq: "${getUserData().id}"}}})
           {
             id
             title
           }
        }`
      },
      update: data => data.users_organizationtable,
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>

<style>
  .limit-characters {
    max-width: 20ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
</style>
